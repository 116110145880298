<template>
  <div class="video">
    <!-- Header -->
    <el-row>
      <el-col :xs="2" :sm="2" :md="2" :lg="2" :xl="2">
        <el-button
          @click="goBack()"
          class="back_button"
          type="danger"
          circle
          icon="el-icon-d-arrow-left"
        ></el-button>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <h1>Video</h1>
      </el-col>
    </el-row>

    <!-- Content -->
    <div class="block" style="overflow: auto">
      <video
        width="800"
        height="600"
        controls
        poster="../images/Ivan-Cupic.jpeg"
      >
        <source
          size="720"
          src="../video/VID-20221028-WA0000.mp4"
          type="video/mp4"
        />
        <source
          size="1080"
          src="../video/VID-20221028-WA0000.mp4"
          type="video/mp4"
        />
        <track
          default
          kind="captions"
          label="English captions"
          src="/path/to/english.vtt"
          srclang="en"
        />
      </video>
    </div>
  </div>
</template>

<script>
export default {
  name: "Video",
  data() {
    return {
      videoId: "YGRtBJuj8oA",
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.back_button {
  margin-top: 15px;
  position: absolute;
}
</style>