<template>
  <div class="messages">
    <!-- Header -->
    <el-row>
      <el-col :xs="2" :sm="2" :md="2" :lg="2" :xl="2">
        <el-button
          @click="goBack()"
          class="back_button"
          type="danger"
          circle
          icon="el-icon-d-arrow-left"
        ></el-button>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <h1 style="margin-left: 50px">Vremenska crta slatkih uspomena</h1>
      </el-col>
    </el-row>

    <!-- Content -->
    <el-row type="flex" justify="center">
      <el-col :xs="24" :sm="20" :md="16" :lg="12" :xl="12">
        <div class="block" style="overflow: auto; min-height: 70vh">
          <loading
            :active="loaderLoading"
            :is-full-page="loaderFullPage"
            :color="loaderColor"
            :opacity="loaderOpacity"
            :height="loaderHeight"
            :width="loaderWidth"
            :loader="loaderStyle"
          ></loading>

          <transition name="el-zoom-in-bottom">
            <el-timeline class="messages_timeline" v-show="showTimeline">
              <el-timeline-item>
                <el-card>
                  <el-image :src="img1" @load="onImgLoad"></el-image>
                  <h3>Započetak jedna solo u "slobodnom" stilu</h3>
                </el-card>
              </el-timeline-item>

              <el-timeline-item>
                <el-card>
                  <el-image :src="img2" @load="onImgLoad"></el-image>
                  <h3>A ne može proći da ne bude jedna s kumom</h3>
                </el-card>
              </el-timeline-item>

              <el-timeline-item>
                <el-card>
                  <el-image :src="img3" @load="onImgLoad"></el-image>
                  <h3>Ana u svom standardnom elementu</h3>
                </el-card>
              </el-timeline-item>

              <el-timeline-item>
                <el-card>
                  <el-image :src="img13" @load="onImgLoad"></el-image>
                  <h3>Maškare!!!</h3>
                </el-card>
              </el-timeline-item>

              <el-timeline-item>
                <el-card>
                  <h3>Sada ćemo par sportskih...</h3>
                  <el-image :src="img4" @load="onImgLoad"></el-image>
                  <h3>Jedna mutna sa omiljenim rukometnim trenerom :)</h3>
                </el-card>
              </el-timeline-item>

              <el-timeline-item>
                <el-card>
                  <el-image :src="img5" @load="onImgLoad"></el-image>
                  <h3>Prva zajedničko gledanje rukometa uživo</h3>
                </el-card>
              </el-timeline-item>

              <el-timeline-item>
                <el-card>
                  <el-image :src="img6" @load="onImgLoad"></el-image>
                  <el-image :src="img7" @load="onImgLoad"></el-image>
                  <h3>Malo s nogometnih terena</h3>
                </el-card>
              </el-timeline-item>

              <el-timeline-item>
                <el-card>
                  <h3>A sada se vraćamo opet na rukomet malo</h3>
                  <el-image :src="img8" @load="onImgLoad"></el-image>
                  <el-image :src="img9" @load="onImgLoad"></el-image>
                </el-card>
              </el-timeline-item>

              <el-timeline-item>
                <el-card>
                  <el-image :src="img17" @load="onImgLoad"></el-image>
                  <h3>Kumice na sezoni pod maslinom</h3>
                </el-card>
              </el-timeline-item>

              <el-timeline-item>
                <el-card>
                  <el-image :src="img10" @load="onImgLoad"></el-image>
                  <h3>Uvijek mora biti barem jedna slika s mora</h3>
                </el-card>
              </el-timeline-item>

              <el-timeline-item>
                <el-card>
                  <h3>A isto tako ne može proći slika bez kave</h3>
                  <el-image :src="img11" @load="onImgLoad"></el-image>
                  <el-image :src="img15" @load="onImgLoad"></el-image>
                  <h3>Svaka riječ je suvišna</h3>
                  <el-image :src="img14" @load="onImgLoad"></el-image>
                  <h3>A ovako to radi mama na domaćem terenu</h3>
                  <el-image :src="img16" @load="onImgLoad"></el-image>
                  <h3>Nakon kave zna se šta slijedi dalje :)</h3>
                </el-card>
              </el-timeline-item>

              <el-timeline-item>
                <el-card>
                  <el-image :src="img12" @load="onImgLoad"></el-image>
                  <h3>Sretan rođendan žele ti tvoji Elena i Mateo</h3>
                </el-card>
              </el-timeline-item>
            </el-timeline>
          </transition>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// Loading effect component
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import img1 from "../images/freedom.jpg";
import img2 from "../images/webcam-toy-foto10.jpg";
import img3 from "../images/Ana_u_elementu.jpg";
import img4 from "../images/Ana_fran_trener.jpg";
import img5 from "../images/rukomet_zg.jpg";
import img6 from "../images/nogomet.jpg";
import img7 from "../images/nogomet2.jpg";
import img8 from "../images/rukomet_maskota.jpg";
import img9 from "../images/rukomet_porec.jpg";
import img10 from "../images/Rovinj.jpg";
import img11 from "../images/kava.jpg";
import img12 from "../images/Elena_Mateo.jpg";
import img13 from "../images/maskare.jpeg";
import img14 from "../images/kava2.jpeg";
import img15 from "../images/kavaPula.jpeg";
import img16 from "../images/piva.jpg";
import img17 from "../images/kumice.jpg";

export default {
  name: "Messages",
  data() {
    return {
      // Display Timeline
      showTimeline: false,
      // Images
      // TODO : Modify the variables to cooperate with your images
      img1: img1,
      img2: img2,
      img3: img3,
      img4: img4,
      img5: img5,
      img6: img6,
      img7: img7,
      img8: img8,
      img9: img9,
      img10: img10,
      img11: img11,
      img12: img12,
      img13: img13,
      img14: img14,
      img15: img15,
      img16: img16,
      img17: img17,
      imagesLoaded: 0,

      totalImages: 17,
      // Loader
      loaderLoading: true,
      loaderColor: "#dd6161",
      loaderOpacity: 1,
      loaderStyle: "dots",
      loaderHeight: 60,
      loaderWidth: 60,
      loaderFullPage: false,
    };
  },
  components: {
    Loading,
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    onImgLoad() {
      this.imagesLoaded += 1;
      if (this.imagesLoaded === this.totalImages) {
        console.log("Images Loaded");
        setTimeout(() => (this.loaderLoading = false), 500);
        setTimeout(() => (this.showTimeline = true), 800);
      }
    },
  },
};
</script>

<style scoped>
.messages_timeline {
  width: 80%;
  height: 83vh;
  margin-left: auto;
  margin-right: auto;
}

.back_button {
  margin-top: 15px;
  position: absolute;
}
</style>
